<template>
  <button type="submit" class="btn btn-primary w-100" :disabled="loading">
    <span v-if="this.loading" class="me-2">
      <font-awesome-icon icon="spinner" spin />
    </span>
    <span v-else>{{ this.text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    text: String,
    loading: Boolean
  }
}
</script>
