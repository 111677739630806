<template>
  <nav class="navbar fixed-top navbar-dark navbar-expand-lg bg-dark">
    <div class="container-fluid">
      <span class="navbar-brand">Wphotos</span>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ this.user.name }}
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li><a class="dropdown-item" href="#" @click.prevent="logoutUser">{{ $t('logout') }}</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">{{ this.user.name }}</h5>
      <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="mb-5">
        <h5>{{ $t('chooseLanguage') }}</h5>
        <div v-if="languages" class="hstack gap-3">
          <button v-for="lang in languages" @click.prevent="switchLanguage(lang)"
                  class="btn btn-lg btn-outline-primary" :class="{'active': lang === this.lang}">
            {{ lang.toUpperCase() }}
          </button>
        </div>
      </div>
      <button class="btn btn-lg btn-danger" type="button" @click.prevent="logoutUser">
        {{ $t('logout') }}
      </button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {mapActions, mapState} from "pinia";
import {useUserStore} from "@/store/auth";
import {settingsStore} from "@/store/settings";

export default {
  data() {
    return {
      languages: [
        'en',
        'sk',
      ]
    }
  },

  computed: {
    ...mapState(useUserStore, ['getUser']),
    ...mapState(settingsStore, ['getLang']),

    user() {
      return this.getUser
    },
    lang() {
      return this.getLang;
    }
  },

  methods: {
    ...mapActions(useUserStore, ['logout']),
    ...mapActions(settingsStore, ['setLang']),

    logoutUser() {
      this.logout();
      router.push({name: 'login'});
    },
    switchLanguage(language) {
      this.$i18n.locale = language;
      this.setLang(language);
    },
  },
}
</script>
