<script>
import router from "@/router";
import BarcodeScanner from "@/components/BarcodeScanner";
import ManualInput from "@/components/ManualInput";
import TheNavbar from "@/components/TheNavbar";
import axios from "@/plugins/axios";
import {mapActions} from "pinia";
import {useUserStore} from "@/store/auth";

export default {
  components: {
    BarcodeScanner,
    ManualInput,
    TheNavbar
  },

  data() {
    return {
      loading: false,
      product: null,
      related: [],
      step: 1,
      error: '',
      photo: '',
      uploadError: '',
      success: ''
    }
  },

  watch: {
    step(newStep, oldStep) {
      if (newStep === 1) {
        if (! this.error && ! this.success) {
          this.$nextTick(() => {
            this.$refs.barcodeScanner.init();
          });
        }
      }
    }
  },

  computed: {
    addProductUrl() {
      return process.env.VUE_APP_BASE_URL + '/api/product';
    },
    photoUploadUrl() {
      return this.product ? process.env.VUE_APP_BASE_URL + '/api/upload-photos/' + this.product.id : '';
    }
  },

  methods: {
    ...mapActions(useUserStore, ['logout']),

    newScan() {
      this.error = '';
      this.success = '';
      this.step = 1;
    },

    processBarcode(barcode) {
      this.loading = true;
      this.error = false;
      this.$refs.manualInput.close();
      axios.post(this.addProductUrl, {ean: barcode}).catch(error => {
        if (error.response.status === 401) {
          this.logout();
          router.push({name: 'login', params: {sessionExpired: true}});
        } else {
          this.handleEanError(error.message);
        }
      }).then(response => {
        if (response.data.errors) {
          this.loading = false;
          this.handleEanError(response.data.message);
          return false;
        }

        this.loading = false;
        this.product = response.data.data.product;
        this.photo = response.data.data.photo;
        this.related = response.data.data.related;
        this.step = 2;
      });
    },

    handleEanError(error) {
      this.error = error;
    },
    handlePhotoUpload(e) {
      this.toggleLoad();
      this.uploadError = '';
      let formData = new FormData(e.target);
      axios.post(e.target.action, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).catch(error => {
        this.uploadError = error.response.data.message;
      }).then(response => {
        this.success = response.data.message;
        this.step = 1;
      }).finally(() => {
        this.toggleLoad();
      });
    },
    toggleLoad() {
      this.loading = ! this.loading;
    },
    reset() {
      this.loading = false;
      this.product = null;
      this.related = [];
      this.step = 1;
      this.error = '';
      this.uploadError = '';
      this.success = '';
    }
  },
}
</script>

<template>
  <div class="d-flex flex-column vh-100 vw-100">
    <the-navbar />
    <div class="bg-light flex-grow-1 vh-100" style="padding-top: 56px">
      <div v-if="step === 1" class="d-flex flex-column h-100">
        <div class="p-4">
          <h1 class="m-0 h2">
            <span>{{ $t('photoShooting.title') }}</span>
            <small class="text-nowrap text-muted d-block">{{ $t('photoShooting.step1.subtitle') }}</small>
          </h1>
        </div>
        <div class="flex-grow-1">
          <div v-if="success || error || loading" class="p-4 pt-0">
            <div v-if="error" class="alert alert-danger">{{ error }}
              <a href="#" @click="newScan" class="alert-link">{{ $t('newScan') }}</a> {{ $t('or') }}
              <a href="#" @click="this.$refs.manualInput.open()" class="alert-link">{{ $t('inputManually') }}</a>
            </div>
            <div v-if="success" class="alert alert-success">{{ success }} <a href="#" @click="newScan" class="alert-link">{{ $t('newScan') }}</a></div>
            <div v-if="loading" class="alert alert-primary">
              <span class="me-2"><font-awesome-icon icon="spinner" spin /></span>
              <span>{{ $t('loading') }}</span>
            </div>
          </div>
          <barcode-scanner v-else ref="barcodeScanner" @barcodeScanned="processBarcode" />
        </div>
        <div class="bg-light position-absolute bottom-0 w-100 p-4">
          <div class="vstack gap-3">
            <button @click="newScan" class="btn btn-lg btn-outline-secondary w-100">
              <span class="me-2"><font-awesome-icon icon="arrows-rotate" /></span>
              <span>{{ $t('refresh') }}</span>
            </button>
            <manual-input ref="manualInput" @barcodeScanned="processBarcode" />
          </div>
        </div>
      </div>

      <div v-if="step === 2" class="h-100 p-4 d-flex justify-content-between flex-column">
        <div class="hstack gap-3 mb-3">
          <h1 class="m-0 h2">
            <span>{{ this.product.name }}</span>
            <small class="text-nowrap text-muted d-block"><span>{{ this.product.goods_id }}</span></small>
          </h1>
          <div v-if="uploadError" class="invalid-feedback">{{ uploadError }}</div>
        </div>

        <div style="overflow-y: auto" class="flex-grow-1 mb-4">
          <div v-if="photo">
            <div class="alert alert-success" role="alert">
              This product already has a photo. <a href="#" class="alert-link">upload new</a>
            </div>
            <img :src="this.photo" :alt="this.product.goods_id" class="img-thumbnail w-100">
          </div>
          <div v-if="related && related.length">
            <p><b>{{ $t('photoShooting.step2.relatedProducts') }}</b></p>
            <ul class="list-group">
              <li v-for="product in related" class="list-group-item d-flex justify-content-between align-items-center">
                {{ product.goods_id }}
                <span class="badge bg-success rounded-pill" v-if="product.photos_count">{{ product.photos_count }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="mb-3">
          <form :action="photoUploadUrl" method="POST" id="photoUploadForm" @submit.prevent="handlePhotoUpload">
            <div class="mb-3">
              <label for="photos" class="form-label">
                <b>{{ $t('photoShooting.step2.photos') }}</b>
              </label>
              <input type="file" id="photos" name="photos[]" class="form-control" :class="{'is-invalid': uploadError}" accept="image/*" capture="camera">
              <div v-if="uploadError" class="invalid-feedback">{{ uploadError }}</div>
            </div>
          </form>
        </div>
        <div class="d-flex hstack gap-3 align-items-stretch justify-content-between">
          <button class="btn btn-lg btn-outline-secondary w-100" @click="reset">
            {{ $t('cancel') }}
          </button>
          <button class="btn btn-lg btn-success w-100" type="submit" form="photoUploadForm" :disabled="this.loading">
            <span v-if="loading" class="me-2"><font-awesome-icon icon="spinner" spin /></span>
            <span v-else>{{ $t('photoShooting.step2.button') }}</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
