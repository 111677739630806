<template>
  <div v-if="updateExists" class="w-100 position-absolute bottom-0 text-center p-4" style="z-index: 9999">
    <div class="alert alert-success m-0" role="alert">
      {{ $t('updateAvailable.message') }} <a href="#" @click.prevent="refreshApp" class="alert-link">{{ $t('updateAvailable.button') }}</a>
    </div>
  </div>

  <router-view/>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },

  computed: {
    appUrl() {
      return process.env.VUE_APP_BASE_URL;
    }
  },

  created() {
    document.addEventListener('swUpdated', this.updateAvailable, {once: true})
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },

  methods: {
    updateAvailable(event) {
      console.log('event prisiel az na updateAvailable metodu ')
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
    },
  },
}
</script>
