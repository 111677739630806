import axios from 'axios';

function getToken() {
    if (window.localStorage.user) {
        return JSON.parse(window.localStorage.user).token;
    } else {
        return null;
    }
}

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(config => {

        let token = getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
    }, error => {
        return Promise.reject(error)
    }
)

export default axios
