import {useUserStore} from "@/store/auth";

export const auth = async (to) => {

    const userStore = useUserStore();
    // const user = await userStore.login();

    if (to.name !== 'login' && !userStore.isAuthenticated) {
        return {name: 'login'}
    } else if (to.name === 'login' && userStore.isAuthenticated) {
        return {name: 'index'}
    }
}
