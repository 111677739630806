import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import {auth} from "@/router/middleware/auth"
import {ip} from "@/router/middleware/ip"

import Login from "@/views/Login";
import PhotoShooting from "@/views/PhotoShooting";
import Unauthorized from "@/views/Unauthorized";

const routes = [
    {
        path: '/',
        name: 'index',
        component: PhotoShooting,
        beforeEnter: [ip, auth],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: [ip],
        props: true
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: Unauthorized,
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

export default router
