import {defineStore} from 'pinia'
import axios from "@/plugins/axios";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        token: null
    }),
    persist: true,
    getters: {
        isAuthenticated(state) {
            return !!state.user
        },
        getUser(state) {
             return state.user
        },

    },
    actions: {
        setUser(data) {
            this.user = data.user
            this.token = data.token;
            sessionStorage.setItem('token', data.token);
        },
        async login() {
            let baseUrl = process.env.VUE_APP_BASE_URL;
            await axios.get(`${baseUrl}/api/user`)
                .then(response => {
                    this.user = response.data;
                }).catch(err => {})
        },
        logout() {
            this.$reset();
            window.sessionStorage.clear();
        },
    },
})

