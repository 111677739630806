<template>

  <div v-if="sessionExpired" class="w-100 position-absolute bottom-0 text-center p-4">
    <div class="alert alert-primary m-0" role="alert">
      {{ $t('sessionExpired') }}
    </div>
  </div>

  <div class="container-fluid vw-100 vh-100 bg-light">
    <div class="d-flex justify-content-center align-items-center w-100 h-100">
      <div>
        <div class="mb-4 text-center">
          <the-logo />
          <h1 class="h3">Wphotos</h1>
        </div>
        <div class="bg-white p-4">
          <form :action="loginUrl" @submit.prevent="handleLogin">
            <div class="mb-3">
              <label for="login" class="form-label">{{ $t('login.login') }}</label>
              <input type="text" id="login" v-model="credentials.login" class="form-control" :class="{'is-invalid': errors.login}">
              <div class="invalid-feedback" v-if="errors.login">{{ errors.login[0] }}</div>
            </div>
            <div class="mb-4">
              <label for="password" class="form-label">{{ $t('login.password') }}</label>
              <input type="password" v-model="credentials.password" id="password" class="form-control" :class="{'is-invalid': errors.password}">
              <div class="invalid-feedback" v-if="errors.password">{{ errors.password[0] }}</div>
            </div>
            <the-button
                type="submit"
                :loading="loading"
                :text="$t('login.button')"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheInput from "@/components/TheInput";
import TheButton from "@/components/TheButton";
import TheLogo from "@/components/TheLogo";
import axios from "@/plugins/axios";
import {mapActions} from "pinia";
import {useUserStore} from "@/store/auth";
import router from "@/router";

export default {
  components: {
    TheInput,
    TheButton,
    TheLogo
  },

  props: {
    sessionExpired: Boolean
  },

  data() {
    return {
      credentials: {
        login: '',
        password: ''
      },
      errors: [],
      loading: false
    }
  },

  computed: {
    loginUrl() {
      let baseUrl = process.env.VUE_APP_BASE_URL;
      return `${baseUrl}/api/login`;
    }
  },

  methods: {
    ...mapActions(useUserStore, ['setUser']),
    toggleLoad() {
      this.loading = ! this.loading;
    },
    handleLogin(e) {
      this.errors = [];
      this.toggleLoad();
      axios.post(e.target.action, this.credentials).catch((error) => {
        this.errors = error.response.data.errors;
      }).then((response) => {
        this.setUser(response.data);
        router.push({name: 'index'});
      }).finally(() => {
        this.toggleLoad();
      });
    }
  },
}
</script>
