import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/custom.css"

import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import router from './router';
import messages from "@/localization/localization";
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import "bootstrap/dist/js/bootstrap.js";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faArrowLeft, faSpinner, faArrowsRotate, faInputNumeric} from '@fortawesome/pro-solid-svg-icons'
import {createI18n} from "vue-i18n";
library.add(faArrowLeft, faSpinner, faArrowsRotate, faInputNumeric)

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const i18n = createI18n({
    locale: localStorage.getItem('lang') || 'en',
    messages,
});

createApp(App)
    .component('FontAwesomeIcon', FontAwesomeIcon)
    .use(router)
    .use(pinia)
    .use(i18n)
    .mount('#app');
