import {defineStore} from 'pinia';

export const settingsStore = defineStore('settings', {
    state: () => ({
        lang: 'sk'
    }),
    persist: true,
    getters: {
        getLang(state) {
            return state.lang
        },
    },
    actions: {
        setLang(language) {
            this.lang = language;
        },
    },
})
