const configuration = {
    numOfWorkers: 8,
    locate: true,
    inputStream: {
        name: 'Live',
        type: 'LiveStream',
        constraints: {
            width: {min: 1280},
            height: {min: 960},
            aspectRatio: {
                min: 1,
                max: 100
            },
            facingMode: "environment"
        },
        area: {
            top: '0%',
            right: '0%',
            left: '0%',
            bottom: '0%'
        }
    },
    frequency: 10,
    decoder: {
        readers: ['ean_reader'],
        debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true
        }
    },
    locator: {
        halfSample: true,
        patchSize: 'medium',
        debug: {
            showCanvas: true,
            showPatches: true,
            showFoundPatches: true,
            showSkeleton: true,
            showLabels: true,
            showPatchLabels: true,
            showRemainingPatchLabels: true,
            boxFromPatches: {
                showTransformed: true,
                showTransformedBox: true,
                showBB: true
            }
        }
    }
};

export default configuration;
