<template>
  <div>
    <div id="scanner" ref="scanner"></div>
  </div>
</template>

<script>
import Quagga from 'quagga';
import configuration from "@/config/quagga/samsungA13";

export default {
  data() {
    return {
      scanner: null
    }
  },

  computed: {
    scannerConfig() {
      let origConfig = configuration;
      origConfig.inputStream.target = this.$refs.scanner;
      return origConfig;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.scanner = Quagga
      this.scanner.init(this.scannerConfig, err => {
        if (err) {
          console.error(err)
          return
        }
        this.scanner.start()
      })
      this.addCallbacks();
    },
    addCallbacks() {
      this.scanner.onProcessed(this.process)
      this.scanner.onDetected(this.detect)
    },

    process(result) {
      let drawingCtx = this.scanner.canvas.ctx.overlay
      let drawingCanvas = this.scanner.canvas.dom.overlay

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width')), parseInt(drawingCanvas.getAttribute('height')))
          result.boxes.filter(box => {
            return box !== result.box
          }).forEach(box => {
            this.scanner.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: 'green', lineWidth: 2})
          })
        }

        if (result.box) {
          this.scanner.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: '#00F', lineWidth: 2})
        }

        if (result.codeResult && result.codeResult.code) {
          this.scanner.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3})
        }
      }
    },

    detect(result) {
      this.scanner.stop();
      this.$emit('barcodeScanned', result.codeResult.code);
    },
  },
}
</script>
