const messages = {

  en: {
    logout: 'Logout',
    sessionExpired: 'Your session has been expired. Please, log in again.',
    loading: 'loading...',
    newScan: 'scan again',
    reset: 'Reset',
    refresh: 'Refresh',
    manual: 'Manual input',
    inputManually: 'enter EAN manually',
    or: 'or',
    typeEan: 'Type EAN',
    cancel: 'Cancel',
    chooseLanguage: 'Choose language',
    updateAvailable: {
      message: 'New update available, please',
      button: 'refresh',
    },
    login: {
      login: "Name",
      password: "Password",
      button: "Login",
      buttonLoading: "Sending...",
    },
    photoShooting: {
      title: 'Photo shooting',
      step1: {
        subtitle: 'Search product',
      },
      step2: {
        subtitle: 'Upload photos',
        photos: 'Photos',
        supportedFormats: 'Supported formats are .jpg, .bmp and .png. Maximum file-size is 4MB',
        relatedProducts: 'Photos will be stored also for these products',
        button: 'Upload',
        buttonLoading: 'Uploading...',
      },
    },
  },

  sk: {
    logout: 'Odhlásiť',
    sessionExpired: 'Platnosť Vašej session vypršala. Prosím, prihláste sa znovu',
    loading: 'načítavam...',
    newScan: 'skenovať znovu',
    reset: 'Obnoviť',
    refresh: 'Obnoviť',
    manual: 'Zadať manuálne',
    inputManually: 'zadať EAN manuálne',
    or: 'alebo',
    typeEan: 'Zadaj EAN',
    cancel: 'Zrušiť',
    chooseLanguage: 'Vyberte jazyk',
    updateAvailable: {
      message: 'Nová verzia dostupná',
      button: 'Načítať',
    },
    login: {
      login: "Prihlasovacie meno",
      password: "Heslo",
      button: "Prihlásiť sa",
      buttonLoading: "Prihlasujem...",
    },
    photoShooting: {
      title: 'Fotenie produktov',
      step1: {
        subtitle: 'Snímaj EAN',
      },
      step2: {
        subtitle: 'Nahrať fotografie',
        photos: 'Fotografie',
        supportedFormats: 'Podporované formáty sú  .jpg, .bmp a .png. Maximálna veľkosť súboru je 4MB',
        relatedProducts: 'Fotografie budú uložené pre tieto produkty',
        button: 'Nahrať',
        buttonLoading: 'Nahrávam...',
      },
    },
  },

};

export default messages;
