import axios from "@/plugins/axios";

export const ip = async (to) => {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    let ipAllowed = await axios.get(`${baseUrl}/api/client-ip`)
        .then(response => response.data);

    if (! ipAllowed) {
        return {name: 'unauthorized'};
    }
}
