<script>
import { Modal } from 'bootstrap';

export default {
  emits: ['barcodeScanned'],
  data() {
    return {
      ean: '',
      modal: null
    }
  },

  mounted() {
    this.modal = new Modal('#myModal', {});

    document.getElementById('myModal').addEventListener('shown.bs.modal', event => {
      this.$refs.searchField.focus();
    });

    document.getElementById('myModal').addEventListener('hidden.bs.modal', event => {
      this.ean = '';
    });
  },

  methods: {
    processManualInput() {
      if (! this.ean) {
        return null;
      }

      this.$emit('barcodeScanned', this.ean);
    },
    close() {
      this.modal.hide();
    },
    open() {
      this.modal.show();
    }
  }
}
</script>

<template>
  <button type="button" class="btn btn-lg btn-outline-secondary w-100" @click="this.open()">
    <span class="me-2"><font-awesome-icon icon="input-numeric" /></span>
    {{ $t('manual') }}
  </button>
  <div class="modal fade" id="myModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h1 class="modal-title text-white fs-5" id="exampleModalLabel">{{ $t('manual') }}</h1>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="processManualInput" ref="form">
            <input type="number" ref="searchField" v-model="ean" class="form-control form-control-lg w-100" :placeholder="$t('typeEan')" required>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" @click="processManualInput" class="btn btn-success">{{ $t('photoShooting.step1.subtitle') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
