<template>
  <div class="mb-3">

    <label :for="name" class="form-label">{{ label }}</label>

    <input type="text" :id="id ?? name" :name="name" :disabled="disabled" class="form-control" :class="{'is-invalid': error, 'is-valid': success}">

    <div class="invalid-feedback" v-if="error">{{ error }}</div>



  </div>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    name: String,
    disabled: Boolean,
    error: String,
  },
}
</script>
